/* eslint-disable no-undef */
/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import axios from 'axios';
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Select from 'react-select';
// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Table,
  Collapse,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

// Table
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
// Breadcrumbs
import Breadcrumbs from '../../components/Common/Breadcrumb';
// Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { NAME_PROJECT, buildTitle, APPLICATION_ID } from 'Apps';
// Redux
import { connect } from 'react-redux';
import {
  readPlanogramRetail,
  updatePlanogramRetail,
  list_history,
} from '../../store/business-point/actions';
import makeAnimated from 'react-select/animated';
import PropTypes from 'prop-types';
import ReactExport from 'react-data-export';
import { CircularProgress } from '@material-ui/core';
let role = '';
const obj = JSON.parse(localStorage.getItem('role'));
if (obj != null) {
  role = obj.role;
}
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const nameBenefit = 'Margin';
const animatedComponents = makeAnimated();
let buildColumnsWondermart = [];
let buildColumnsPrice = [];

let buildColumns2 = [];
let buildPlanogramList = {};
let buildColumnList = {};
let validate = {};
let removed = [];

let dataInput = [];
class BusinessPointPlanogram extends Component {
  constructor(props) {
    super(props);
    const buttonOptions = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'buttonOptions'],
      []
    );
    const buttonList = [
      { label: 'KA1', value: 'KA1' },
      { label: 'KA2', value: 'KA2' },
      { label: 'KA3', value: 'KA3' },
      { label: 'KA4', value: 'KA4' },
      { label: 'KA5', value: 'KA5' },
      { label: 'KA6', value: 'KA6' },
      { label: 'KA7', value: 'KA7' },
      { label: 'KA8', value: 'KA8' },
      { label: 'KA9', value: 'KA9' },
      { label: 'KA10', value: 'KA10' },
      { label: 'KB1', value: 'KB1' },
      { label: 'KB2', value: 'KB2' },
      { label: 'KB3', value: 'KB3' },
      { label: 'KB4', value: 'KB4' },
      { label: 'KB5', value: 'KB5' },
      { label: 'KB6', value: 'KB6' },
      { label: 'KB7', value: 'KB7' },
      { label: 'KB8', value: 'KB8' },
      { label: 'KB9', value: 'KB9' },
      { label: 'KB10', value: 'KB10' },
    ];
    const buttonListRdboks = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: '10', value: '10' },
      { label: '11', value: '11' },
      { label: '12', value: '12' },
      { label: '13', value: '13' },
      { label: '14', value: '14' },
      { label: '15', value: '15' },
      { label: '16', value: '16' },
      { label: '17', value: '17' },
      { label: '18', value: '18' },
      { label: '19', value: '19' },
      { label: '20', value: '20' },
    ];
    const buttonListFuji = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: '10', value: '10' },
      { label: '11', value: '11' },
      { label: '12', value: '12' },
      { label: '13', value: '13' },
      { label: '14', value: '14' },
      { label: '15', value: '15' },
      { label: '16', value: '16' },
      { label: '17', value: '17' },
      { label: '18', value: '18' },
      { label: '19', value: '19' },
      { label: '20', value: '20' },
      { label: '21', value: '21' },
      { label: '22', value: '22' },
      { label: '23', value: '23' },
      { label: '24', value: '24' },
      { label: '25', value: '25' },
      { label: '26', value: '26' },
      { label: '27', value: '27' },
      { label: '28', value: '28' },
      { label: '29', value: '29' },
      { label: '30', value: '30' },
      { label: '31', value: '31' },
      { label: '32', value: '32' },
      { label: '33', value: '33' },
      { label: '34', value: '34' },
      { label: '35', value: '35' },
      { label: '36', value: '36' },
      { label: '37', value: '37' },
      { label: '38', value: '38' },
      { label: '39', value: '39' },
      { label: '40', value: '40' },
      { label: '41', value: '41' },
      { label: '42', value: '42' },
      { label: '43', value: '43' },
      { label: '44', value: '44' },
      { label: '45', value: '45' },
      { label: '46', value: '46' },
      { label: '47', value: '47' },
      { label: '48', value: '48' },
      { label: '49', value: '49' },
      { label: '50', value: '50' },
      { label: '51', value: '51' },
      { label: '52', value: '52' },
      { label: '53', value: '53' },
      { label: '54', value: '54' },
      { label: '55', value: '55' },
      { label: '56', value: '56' },
      { label: '57', value: '57' },
      { label: '58', value: '58' },
      { label: '59', value: '59' },
      { label: '60', value: '60' },
      { label: '60', value: '60' },
      { label: '61', value: '61' },
      { label: '62', value: '62' },
      { label: '63', value: '63' },
      { label: '64', value: '64' },
      { label: '65', value: '65' },
      { label: '66', value: '66' },
      { label: '67', value: '67' },
      { label: '68', value: '68' },
      { label: '69', value: '69' },
      { label: '70', value: '70' },
      { label: '71', value: '71' },
      { label: '72', value: '72' },
      { label: '73', value: '73' },
      { label: '74', value: '74' },
      { label: '75', value: '75' },
      { label: '76', value: '76' },
      { label: '77', value: '77' },
      { label: '78', value: '78' },
      { label: '79', value: '79' },
      { label: '80', value: '80' },
    ];
    const orderOptions = [
      { label: '1', value: '1' },
      { label: '2', value: '2' },
      { label: '3', value: '3' },
      { label: '4', value: '4' },
      { label: '5', value: '5' },
      { label: '6', value: '6' },
      { label: '7', value: '7' },
      { label: '8', value: '8' },
      { label: '9', value: '9' },
      { label: '10', value: '10' },
      { label: '11', value: '11' },
      { label: '12', value: '12' },
      { label: '13', value: '13' },
      { label: '14', value: '14' },
      { label: '15', value: '15' },
      { label: '16', value: '16' },
      { label: '17', value: '17' },
      { label: '18', value: '18' },
      { label: '19', value: '19' },
      { label: '20', value: '20' },
      { label: '21', value: '21' },
      { label: '22', value: '22' },
      { label: '23', value: '23' },
      { label: '24', value: '24' },
      { label: '25', value: '25' },
      { label: '26', value: '26' },
      { label: '27', value: '27' },
      { label: '28', value: '28' },
      { label: '29', value: '29' },
      { label: '30', value: '30' },
      { label: '31', value: '31' },
      { label: '32', value: '32' },
      { label: '33', value: '33' },
      { label: '34', value: '34' },
      { label: '35', value: '35' },
      { label: '36', value: '36' },
      { label: '37', value: '37' },
      { label: '38', value: '38' },
      { label: '39', value: '39' },
      { label: '40', value: '40' },
      { label: '41', value: '41' },
      { label: '42', value: '42' },
      { label: '43', value: '43' },
      { label: '44', value: '44' },
      { label: '45', value: '45' },
      { label: '46', value: '46' },
      { label: '47', value: '47' },
      { label: '48', value: '48' },
      { label: '49', value: '49' },
      { label: '50', value: '50' },
      { label: '51', value: '51' },
      { label: '52', value: '52' },
      { label: '53', value: '53' },
      { label: '54', value: '54' },
      { label: '55', value: '55' },
      { label: '56', value: '56' },
      { label: '57', value: '57' },
      { label: '58', value: '58' },
      { label: '59', value: '59' },
      { label: '60', value: '60' },
      { label: '60', value: '60' },
      { label: '61', value: '61' },
      { label: '62', value: '62' },
      { label: '63', value: '63' },
      { label: '64', value: '64' },
      { label: '65', value: '65' },
      { label: '66', value: '66' },
      { label: '67', value: '67' },
      { label: '68', value: '68' },
      { label: '69', value: '69' },
      { label: '70', value: '70' },
      { label: '71', value: '71' },
      { label: '72', value: '72' },
      { label: '73', value: '73' },
      { label: '74', value: '74' },
      { label: '75', value: '75' },
      { label: '76', value: '76' },
      { label: '77', value: '77' },
      { label: '78', value: '78' },
      { label: '79', value: '79' },
      { label: '80', value: '80' },
    ];
    this.state = {
      confirm_alert: false,
      buttonValue: [],
      dataInput,
    };
    var editable = true;
    if (role == 'refiller') {
      editable = false;
    }
    const customStyles = {
      control: (base, state) => ({
        ...base,
        background: '#FDC8C8',
        fontSize: '13px',
      }),
    };
    const styles = {
      control: (base, state) => ({
        ...base,
        fontSize: '13px',
      }),
    };

    buildColumnsWondermart = (productMap, imageMap) => {
      const productList = [];
      const windowDimension = window.innerWidth;
      const maxStock = 40;
      const isMobile = windowDimension <= 640;
      for (const sku in productMap) {
        productList.push({
          label: productMap[sku],
          value: sku,
        });
      }

      const column = [
        {
          dataField: 'selection',
          text: 'Selection',
          editable: false,
          headerStyle: {
            width: 20,
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },

          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          editable: false,
          dataField: 'image',
          text: 'Image',
          headerStyle: {
            width: 50,
          },
          formatter: (cell, row, rowIndex, imageMap) => (
            <>
              <img
                src={safeDeepGet(imageMap, row.product, '')}
                onClick={() =>
                  this.handleImage(safeDeepGet(imageMap, row.product, ''))
                }
                width="50"
                height="50"
              />
            </>
          ),

          formatExtraData: imageMap,
          style: (cell, row, rowIndex, colIndex, imageMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'product',
          text: 'Product',
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              return productList;
            },
          },
          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <p>{safeDeepGet(productMap, row.product, '')}</p>
            </>
          ),

          formatExtraData: productMap,
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
                width: '150px',
              };
            }
            return {
              width: '150px',
            };
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'price',
          text: 'Price (Rp)',
          editorRenderer: (
            editorProps,
            value,
            row,
            column,
            rowIndex,
            columnIndex
          ) => (
            <PriceEditor
              {...editorProps}
              value={value}
              row={row}
              modal={this.state.modal}
              onTogle={this.toggle}
              onPriceChange={this.handlePriceChange}
            />
          ),

          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'stock',
          text: 'Stock',
          headerStyle: {
            width: 120,
          },
          validator: (newValue, row, column) => {
            if (isNaN(newValue)) {
              return {
                valid: false,
                message: 'Stock harus angka',
              };
            }
            if (newValue > maxStock) {
              return {
                valid: false,
                message: 'Stock tidak bisa melebihi 40',
              };
            }
            return true;
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },

        {
          dataField: 'active',
          text: 'Active',
          editor: {
            type: Type.CHECKBOX,
          },
          headerStyle: {
            width: 20,
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <Input
                type="checkbox"
                defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
              />
            );
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
      ];

      return column;
    };
    buildColumnsPrice = productMap => {
      const productList = [];
      const windowDimension = window.innerWidth;
      const isMobile = windowDimension <= 640;
      for (const sku in productMap) {
        productList.push({
          label: productMap[sku],
          value: sku,
        });
      }
      const mobile = [
        {
          dataField: 'selection',
          text: 'Selection',
          editable: false,

          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },

          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'price',
          text: 'Price (Rp)',
          validator: (newValue, row, column) => {
            if (!/^\d+$/.test(newValue)) {
              return {
                valid: false,
              };
            }
            return true;
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'active',
          text: 'Active',
          editor: {
            type: Type.CHECKBOX,
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <Input
                type="checkbox"
                defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
              />
            );
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stock <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
      ];
      const price = mobile;
      return price;
    };
    buildColumns2 = (productMap, imageMap, buttonOptions) => {
      const productList = [];
      const windowDimension = window.innerWidth;
      const isMobile = windowDimension <= 640;
      for (const sku in productMap) {
        productList.push({
          label: productMap[sku],
          value: sku,
        });
      }
      return [
        {
          editable: false,
          dataField: 'image',
          text: 'Image',
          formatter: (cell, row, rowIndex, imageMap) => (
            <>
              <img
                src={safeDeepGet(imageMap, row.product, '')}
                onClick={() =>
                  this.handleImage(safeDeepGet(imageMap, row.product, ''))
                }
                width="50"
                height="50"
              />
            </>
          ),

          formatExtraData: imageMap,
          style: (cell, row, rowIndex, colIndex, imageMap) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'selection',
          text: 'Button',
          editable: false,
          // headerStyle:{
          //   width: 20
          // },
          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <p>{row.selection}</p>
            </>
          ),
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
                width: '50px',
              };
            }
            return {
              width: '50px',
            };
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },

        {
          editable: false,
          dataField: 'product',
          text: 'Product',
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              return productList;
            },
          },
          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <p>{safeDeepGet(productMap, row.product, '')}</p>
            </>
          ),

          formatExtraData: productMap,
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
                width: '150px',
              };
            }
            return {
              width: '150px',
            };
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'order',
          text: 'Order',
          editor: {
            type: Type.SELECT,
            getOptions: (setOptions, { row, column }) => {
              return orderOptions;
            },
          },
          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <p>{row.order}</p>
            </>
          ),
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'price',
          text: 'Price (Rp)',
          editable: false,
          validator: (newValue, row, column) => {
            if (!/^\d+$/.test(newValue)) {
              return {
                valid: false,
              };
            }
            return true;
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
                width: '50px',
              };
            }
            return {
              width: '50px',
            };
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },

        {
          dataField: 'column',
          text: 'Column',
          editable: false,

          formatter: (cell, row, rowIndex, productMap) => (
            <>
              <Select
                isDisabled={true}
                defaultValue={row.dataInput[row.selection]}
                isMulti
                name="colors"
                options={this.state.columnOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={value => this.handleOnChange(value, row.selection)}
                closeMenuOnSelect={false}
                styles={row.stockColumn <= 3 ? customStyles : styles}
              />
            </>
          ),

          formatExtraData: productMap,
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },

          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
        {
          dataField: 'active',
          text: 'Active',
          editor: {
            type: Type.CHECKBOX,
          },
          formatter: (cell, row, rowIndex) => {
            return (
              <Input
                type="checkbox"
                defaultChecked={safeDeepGet(row, ['active'], 'off') === 'on'}
              />
            );
          },
          style: (cell, row, rowIndex, colIndex, productMap) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
                width: '50px',
              };
            }
            return {
              width: '50px',
            };
          },
          editCellStyle: (cell, row, rowIndex, colIndex) => {
            if (row.stockColumn <= 3) {
              return {
                backgroundColor: '#FDC8C8',
              };
            }
          },
        },
      ];
    };
    const buildPlanogram = (selection, planogramMap) => {
      const product = safeDeepGet(planogramMap, [selection, 'product'], '');
      const nameProduct = safeDeepGet(
        planogramMap,
        [selection, 'nameProduct'],
        ''
      );
      const price = safeDeepGet(planogramMap, [selection, 'price'], 0);
      const stock = safeDeepGet(planogramMap, [selection, 'stock'], 0);
      const order = safeDeepGet(planogramMap, [selection, 'order'], 0);
      const hpp = safeDeepGet(planogramMap, [selection, 'hpp'], 0);
      const share = safeDeepGet(planogramMap, [selection, 'share'], 0);
      const column = safeDeepGet(
        planogramMap,
        [selection.slice(1, 4), 'column'],
        ''
      );
      if (column) {
        var text = column.split(',');
        var arr_column = [];
      }
      for (const i in text) {
        arr_column.push({
          label: text[i],
          value: text[i],
        });
      }
      // this.state.dataInput['1'] = arr_column;
      // this.state.dataInput[selection] = arr_column;
      const active =
        safeDeepGet(planogramMap, [selection, 'active'], false) === true
          ? 'on'
          : 'off';
      var stockColumn = 0;
      for (const value in arr_column) {
        const stock = safeDeepGet(
          planogramMap,
          [arr_column[value].value, 'stock'],
          0
        );
        stockColumn += stock;
      }
      return {
        key: selection,
        selection,
        product,
        nameProduct,
        price,
        stock,
        order,
        active,
        column,
        arr_column,
        stockColumn,
        hpp,
        share,
      };
    };
    buildPlanogramList = planogramMap => {
      const planogramList = [];
      const columnList = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'column'],
        []
      );
      for (const i in columnList) {
        const column = columnList[i];
        planogramList.push(buildPlanogram(column, planogramMap));
      }
      return planogramList;
    };
    buildColumnList = (planogramMap, productMap) => {
      const columnButtonList = [];
      const butttonList = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'button'],
        []
      );
      for (const i in butttonList) {
        const button = butttonList[i];
        columnButtonList.push(
          buildColumnButton(button, planogramMap, productMap)
        );
      }
      return columnButtonList;
    };
    const buildColumnButton = (selection, planogramMap) => {
      const column = safeDeepGet(planogramMap, [selection, 'column'], '');
      const product = safeDeepGet(planogramMap, [selection, 'product'], '');
      const nameProduct = safeDeepGet(
        planogramMap,
        [selection, 'nameProduct'],
        ''
      );
      const price = safeDeepGet(planogramMap, [selection, 'price'], 0);
      const order = safeDeepGet(planogramMap, [selection, 'order'], 0);

      const active =
        safeDeepGet(planogramMap, [selection, 'active'], false) === true
          ? 'on'
          : 'off';

      if (column) {
        var text = column.split(',');
        var arr_column = [];
      }
      for (const i in text) {
        arr_column.push({
          label: text[i].toString(),
          value: text[i].toString(),
        });
      }
      const { dataInput } = this.state;
      dataInput[selection] = arr_column;
      var stockColumn = 0;
      for (const value in arr_column) {
        const stock = safeDeepGet(
          planogramMap,
          [arr_column[value].value, 'stock'],
          0
        );
        stockColumn += stock;
      }
      return {
        key: selection,
        selection,
        column,
        dataInput,
        arr_column,
        product,
        nameProduct,
        price,
        order,
        active,
        stockColumn,
      };
    };
    const { data, type } = props;
    const deviceId = safeDeepGet(data, 'deviceId');
    const deviceType = type;
    const history = safeDeepGet(data, 'history');
    const massage = safeDeepGet(this.props, ['result', 'message'], {});
    this.state = {
      dataPrice: {},
      listButton: buttonList,
      buttonListFuji: buttonListFuji,
      buttonValue: [],
      dataInput,
      massage,
      deviceId,
      deviceType,
      col1: false,
      col2: true,
      modal: true,
      columnsWondermart: buildColumnsWondermart({}),
      columnsPrice: buildColumnsPrice({}),
      columnsButton: buildColumns2({}),
      planogram: buildPlanogramList({}),
      // buttonColumns: buildColumnList({}),
      button: buildColumnList({}),
      productMap: {},
      isLoading: true,
    };

    this.tableRef = React.createRef();
    this.buttonRef = React.createRef();
    this.priceRef = React.createRef();
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    // this.handleOnClick = this.handleOnClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidDate = this.handleValidDate.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handlePriceChange = this.handlePriceChange.bind(this);
    this.handleImage = this.handleImage.bind(this);
  }
  handlePriceChange = value => {
    const selection = safeDeepGet(value, 'selection', '');
    const product = safeDeepGet(value, 'product', '');
    safeDeepSet(this.state.dataPrice, product, value);
  };
  t_col1() {
    this.setState({
      col1: !this.state.col1,
    });
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
    });
  }
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  get_history() {
    const { deviceId } = this.state;
    const payload = {
      device_id: deviceId,
    };
    this.props.GetHistory(payload);
  }
  async componentDidMount() {
    const { deviceId } = this.state;
    const response = await axios.get(
      'https://asia-southeast2-iotera-vending.cloudfunctions.net/getTime'
    );
    const body = response.data;
    this.setState({
      timeStampNow: body.timestamp,
    });
    this.props.ReadPlanogram(deviceId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.readPlanogramResult !== prevProps.readPlanogramResult) {
      const code = safeDeepGet(
        this.props,
        ['readPlanogramResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        const productMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'products'],
          {}
        );
        const imageMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'images'],
          {}
        );
        const columnOptions = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'columnOptions'],
          []
        );
        const buttonOptions = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'buttonOptions'],
          []
        );
        const planogramMap = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'planogram'],
          {}
        );
        const history = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'planogram'],
          []
        );
        const name = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'name'],
          []
        );
        const timeStamp = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'time_stamp'],
          0
        );
        const user = safeDeepGet(
          this.props,
          ['readPlanogramResult', 'body', 'history', 'user'],
          []
        );
        this.setState({
          columnsWondermart: buildColumnsWondermart(productMap, imageMap),
          columnsButton: buildColumns2(productMap, imageMap, buttonOptions),
          columnsPrice: buildColumnsPrice(productMap),
          planogram: buildPlanogramList(planogramMap),
          button: buildColumnList(planogramMap, productMap),
          history: history,
          name: name,
          timeStamp: timeStamp,
          user: user,
          productMap,
          imageMap,
          columnOptions,
          buttonOptions,
          planogramMap,
          isLoading: false,
        });
      }
    }
    if (this.props.resultHistory !== prevProps.resultHistory) {
      const code = safeDeepGet(
        this.props,
        ['resultHistory', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code === Result.SUCCESS) {
        const body = safeDeepGet(this.props, ['resultHistory', 'body']);
        if (body != null) {
          const history = safeDeepGet(body[0], 'planogram', []);
          const name = safeDeepGet(body[0], 'name', '');
          const timeStamp = safeDeepGet(body[0], 'time_stamp', 0);
          const user = safeDeepGet(body[0], 'user', '');
          this.setState({
            history: history,
            name: name,
            timeStamp: timeStamp,
            timeStampDataBase: timeStamp,
            user: user,
          });
        }
      }
    }
    if (this.props.result !== prevProps.result) {
      const code = safeDeepGet(
        this.props,
        ['result', 'code'],
        Result.UNKNOWN_ERROR
      );
      const message = safeDeepGet(
        this.props,
        ['result', 'message', 'text'],
        ''
      );
      const serverless_text = safeDeepGet(
        this.props,
        ['result', 'message', 'message'],
        message
      );
      const serverless_code = safeDeepGet(
        this.props,
        ['result', 'message', 'result'],
        Result.UNKNOWN_ERROR
      );
      const mssage_serveless =
        'Message: ' + serverless_text + ' error code ' + serverless_code;
      if (code === Result.SUCCESS) {
        this.setState({ loading: false });

        this.showToast('Update Planogram Successful', code, mssage_serveless);
        setTimeout(() => window.location.reload(), 2000);
      } else if (code !== Result.UNKNOWN_ERROR) {
        this.setState({ loading: false });

        this.showToast('Update Planogram Failed', code, mssage_serveless);
      }
    }
  }
  showToast(messagee, code, txt) {
    if (messagee == 'Update Planogram Successful') {
      var toastType = 'success';
      var message = messagee;
    } else {
      var toastType = 'error';
      var message = messagee + ' Kode Error: ' + code + ' ' + txt;
    }
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleCancel(event) {
    history.push('/business-point/list');
  }
  handleOnClick(item) {}
  handleOnChange(value, selection) {
    const { listButton, buttonValue, dataInput } = this.state;
    const selectedValue = [];
    for (const i in value) {
      selectedValue.push(value[i]);
    }
    buttonValue[selection] = selectedValue;
    dataInput[selection] = selectedValue;

    safeDeepGet(buttonValue[selection], []);
    if (value.length == 0) {
      listButton.splice(0, 0, ...removed);
      removed = [];
    }
    for (const key in value) {
      var index = listButton.indexOf(value[key]);

      if (index !== -1) {
        const remove = listButton.splice(index, 1);
        removed.push(value[key]);
      }
    }
  }
  handleOkClick = () => {
    const { dataPlanogram } = this.state;
    this.props.UpdatePlanogram(dataPlanogram);
    this.setState({
      confirm_alert: false,
      dynamic_title: 'Updated',
      dynamic_description: 'Planogram sudah di update',
    });
  };
  handleSubmit(event) {
    this.get_history();
    this.setState({
      loading: true,
    });

    const {
      deviceId,
      productMap,
      dataInput,
      imageMap,
      dataPrice,
      timeStampNow,
      deviceType,
    } = this.state;

    const type = safeDeepGet(this.props, ['readPlanogramResult', 'type'], []);
    const data = this.tableRef.current.props.data;
    const button = this.buttonRef.current.props.data;
    const planogramMap = {};
    for (const i in data) {
      const planogram = data[i];
      const selection = planogram['selection'];
      const arr_input = dataInput[selection];
      var str = '';
      for (const i in arr_input) {
        const data_arr = safeDeepGet(arr_input[i], ['value'], []);
        const j = Number(i);
        if (j + 1 == arr_input.length) {
          str += data_arr;
        } else {
          str += data_arr + ',';
        }
      }
      const product = planogram['product'];

      let image = safeDeepGet(imageMap, product);
      if (image == '') {
        image =
          'https://firebasestorage.googleapis.com/v0/b/iotera-vending.appspot.com/o/no%20image.png?alt=media&token=8779c266-a184-46db-b626-6b4d931176a6';
      }
      planogramMap[selection] = {
        stock: Number(planogram['stock']),
        active: planogram['active'] == 'on',
        product,
        price: Number(planogram['price']),
        image,
        name: safeDeepGet(productMap, product, '-'),
      };
    }
    for (const i in button) {
      const planogram = button[i];
      const selection = planogram['selection'];
      const arr_input = dataInput[selection];
      var str = '';
      for (const i in arr_input) {
        const data_arr = safeDeepGet(arr_input[i], ['value'], []);
        const j = Number(i);
        if (j + 1 == arr_input.length) {
          str += data_arr;
        } else {
          str += data_arr + ',';
        }
      }

      const product = planogram['product'];
      const order = planogram['order'];
      var image = safeDeepGet(imageMap, product);
      if (image == '') {
        image =
          'https://firebasestorage.googleapis.com/v0/b/iotera-vending.appspot.com/o/no%20image.png?alt=media&token=8779c266-a184-46db-b626-6b4d931176a6';
      }
      const active = planogram['active'];
      this.get_history();

      planogramMap[selection] = {
        column: str,
        product,
        name: safeDeepGet(productMap, product, '-'),
        image: image,
        order: Number(order),
        price: Number(planogram['price']),
        active: planogram['active'] == 'on',
      };
    }
    this.setState({
      dataPlanogram: {
        id: deviceId,
        planogram: planogramMap,
        type: deviceType,
        device_type: deviceType,
        latest_planogram: this.state.planogramMap,
        dataPrice,
      },
    });
    setTimeout(
      function () {
        const body = safeDeepGet(this.props, ['resultHistory', 'body']);
        const tsData = safeDeepGet(body, [0, 'time_stamp'], 0);
        if (tsData == 0) {
          this.props.UpdatePlanogram(this.state.dataPlanogram);
        } else if (timeStampNow > tsData) {
          this.props.UpdatePlanogram(this.state.dataPlanogram);
        } else {
          this.setState({
            confirm_alert: true,
          });
        }
        //Start the timer
      }.bind(this),
      2000
    );
  }
  handleValidDate = date => {
    if (date != null || date != undefined) {
      const date1 = new Date(date).toLocaleDateString('en-GB');
      const time1 = new Date(date).toLocaleTimeString('en-GB');
      return date1 + ' ' + time1;
    } else {
      return '-';
    }
  };
  handleImage = arg => {
    const trx = arg;
    this.setState({
      trx,
    });
    // this.toggleDetail();
    window.open(arg);
  };
  render() {
    const loading = safeDeepGet(this.state, 'loading', false);
    const drole = localStorage.getItem('accessNav');
    const jsonRole = JSON.parse(drole);
    const guest = safeDeepGet(jsonRole, ['Business Point', 'guest'], 'off');
    const {
      columns,
      columnsPrice,
      columnsWondermart,
      planogram,
      columnsButton,
      dataInput,
      button,
      history,
      name,
      timeStamp,
      isLoading,
      user,
    } = this.state;
    const dataButton = [];
    for (const selection in dataInput) {
      const arr_input = dataInput[selection];
      var str = '';
      for (const i in arr_input) {
        const data_arr = safeDeepGet(arr_input[i], ['value'], []);
        const j = Number(i);
        if (j + 1 == arr_input.length) {
          str += data_arr;
        } else {
          str += data_arr + ', ';
        }
      }
      if (selection.slice(0, 1) != 'K') {
        dataButton.push({ key: selection, value: str });
      }
    }
    const columnList = safeDeepGet(
      this.props,
      ['readPlanogramResult', 'column'],
      []
    );
    const type = safeDeepGet(this.props, ['readPlanogramResult', 'type'], []);
    const dataColumn = [];
    for (const selection in columnList) {
      const column = columnList[selection];
      dataColumn.push({ key: column, value: str });
    }

    const filename = name + '_' + '_' + this.handleValidDate(timeStamp);
    const windowDimension = window.innerWidth;
    const isMobile = windowDimension <= 640;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Planoggram')}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Business Point" breadcrumbItem="Planogram" />
            {this.state.confirm_alert ? (
              <SweetAlert
                title="Terdapat data planogram baru"
                warning
                showCancel
                confirmButtonText="Ya, Update dengan data baru!"
                cancelBtnText="Tidak, Resfresh page"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => this.handleOkClick()}
                onCancel={() => window.location.reload()}
              >
                Apakah anda yakin akan melakukan update data?
              </SweetAlert>
            ) : null}
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}
            <Row>
              <Col lx="8">
                <div className="accordion accordion-flush" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button fw-medium mb-4 h5"
                        type="button"
                        onClick={this.t_col2}
                        style={{ cursor: 'pointer' }}
                      >
                        Column Vending Machine
                      </button>
                    </h2>

                    <Collapse
                      isOpen={this.state.col2}
                      className="accordion-collapse"
                    >
                      <Card>
                        <CardBody>
                          <CardTitle className="mb-4 h5"></CardTitle>
                          {isLoading ? (
                            <div className="text-center">
                              <CircularProgress className="text-center"></CircularProgress>
                            </div>
                          ) : (
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField="selection"
                                bootstrap4={true}
                                data={planogram}
                                columns={columnsWondermart}
                                cellEdit={cellEditFactory({
                                  mode: 'click',
                                  blurToSave: true,
                                })}
                                bordered={false}
                                ref={this.tableRef}
                              />
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
                </div>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h5">
                        Latest Changes Planogram by
                      </CardTitle>

                      <Row className="align-items-center">
                        <Col lg="9" className="align-self-center">
                          <div className="table-responsive">
                            <Table className="table align-middle table-nowrap table-hover mb-0">
                              <thead>
                                <tr>
                                  <th scope="col">Email</th>
                                  <th scope="col">Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{user}</td>
                                  <td>
                                    {timeStamp == null
                                      ? '-'
                                      : this.handleValidDate(timeStamp)}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>

                        <Col
                          lg="3"
                          className="d-none d-lg-block"
                          align="center"
                        >
                          <div className="clearfix mt-4 mt-lg-0">
                            <ExcelFile
                              filename={filename}
                              element={
                                <button
                                  type="button"
                                  className="btn btn-primary w-sm"
                                >
                                  <i className="mdi mdi-download d-block font-size-16"></i>
                                  Download
                                </button>
                              }
                            >
                              <ExcelSheet data={history} name="Planogram">
                                <ExcelColumn label="Selection" value="key" />
                                <ExcelColumn label="Product" value="name" />
                                <ExcelColumn
                                  label="Product SKU"
                                  value="product"
                                />
                                <ExcelColumn
                                  label="Product Price"
                                  value="price"
                                />
                                <ExcelColumn label="Stock" value="stock" />
                                <ExcelColumn label="Active" value="active" />
                                <ExcelColumn label="Column" value="column" />
                              </ExcelSheet>
                            </ExcelFile>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Col>
              <Col xl="4">
                <Row>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h5">Column Layout</CardTitle>
                      <p className="card-title-desc">Layout kolom mesin</p>
                      <div className="card bg-primary text-white mb-0">
                        <CardBody>
                          <div className="table-responsive">
                            <Table className="table table-sm mb-0 text-white text-center">
                              <thead>
                                <tr>
                                  <th colSpan="10">Column</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  {dataButton.map(function (item, i) {
                                    return (
                                      <>
                                        {i > 19 ? (
                                          i < 30 ? (
                                            <td key={'A' + i}>{item.key}</td>
                                          ) : null
                                        ) : null}
                                      </>
                                    );
                                  })}
                                </tr>
                                <tr>
                                  {dataButton.map(function (item, i) {
                                    return (
                                      <>
                                        {i > 9 ? (
                                          i < 20 ? (
                                            <td key={'A' + i}>{item.key}</td>
                                          ) : null
                                        ) : null}
                                      </>
                                    );
                                  })}
                                </tr>
                                <tr>
                                  {dataButton.map(function (item, i) {
                                    return (
                                      <>
                                        {i > 9 ? null : (
                                          <td key={'A' + i}>{item.key}</td>
                                        )}
                                      </>
                                    );
                                  })}
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </div>
                    </CardBody>
                  </Card>
                </Row>
                <Row></Row>
                <div
                  style={{
                    // backgroundColor: 'blue',
                    position: 'fixed',
                    bottom: '20px',
                    right: '80px',
                    // width: '600px',
                    height: '50px',
                    // display: 'flex',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    // boxShadow: '0 2px 10px rgba(0, 0, 0, 0.15)',
                    zIndex: 999,
                  }}
                >
                  <Row>
                    <Col
                      sm="6"
                      className="mt-3"
                      style={{
                        padding: '0px',
                        width: '200px',
                      }}
                      s
                    >
                      <p className="text-start" style={{ fontSize: '10px' }}>
                        Latest Planogram{' '}
                        {timeStamp == null
                          ? '-'
                          : this.handleValidDate(timeStamp)}
                      </p>
                    </Col>
                    <Col
                      sm="6"
                      style={{
                        padding: '0px',
                        width: '200px',
                      }}
                    >
                      {isLoading ? (
                        <div className="d-flex flex-wrap gap-2 float-end ">
                          <Link
                            to="/business-point/list"
                            color="secondary"
                            className="btn btn-secondary"
                            role="button"
                            // onClick={this.handleCancel}
                            disabled={loading}
                          >
                            Cancel
                          </Link>{' '}
                          {loading ? (
                            <button type="button" className="btn btn-dark ">
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{' '}
                              Loading
                            </button>
                          ) : guest == 'off' ? (
                            <Button
                              color="primary"
                              onClick={this.componentDidMount}
                              disabled={loading}
                            >
                              Refresh
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <div className="d-flex flex-wrap gap-2 float-end ">
                          <Link
                            to="/business-point/list"
                            color="secondary"
                            className="btn btn-secondary"
                            role="button"
                            // onClick={this.handleCancel}
                            disabled={loading}
                          >
                            Cancel
                          </Link>{' '}
                          {loading ? (
                            <button type="button" className="btn btn-dark ">
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{' '}
                              Loading
                            </button>
                          ) : guest == 'off' ? (
                            <Button
                              color="primary"
                              onClick={this.handleSubmit}
                              disabled={loading}
                            >
                              Simpan
                            </Button>
                          ) : (
                            <></>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
                {/* <div className="d-flex flex-wrap gap-2 float-end mb-4">
                  <Link
                    to="/business-point/list"
                    color="secondary"
                    className="btn btn-secondary"
                    role="button"
                    // onClick={this.handleCancel}
                    disabled={loading}
                  >
                    Cancel
                  </Link>{' '}
                  <Button
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={loading}
                  >
                    Simpan
                  </Button>
                </div> */}
              </Col>
            </Row>

            <Row>
              <Col lx="8">
                <div className="accordion accordion-flush" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button fw-medium mb-4 h5"
                        type="button"
                        onClick={this.t_col1}
                        style={{ cursor: 'pointer' }}
                      >
                        Button Vending Machine
                      </button>
                    </h2>

                    <Collapse
                      isOpen={this.state.col1}
                      className="accordion-collapse"
                    >
                      <Card>
                        <CardBody>
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField="selection"
                              // data={planogram}
                              columns={columnsButton}
                              data={button}
                              cellEdit={cellEditFactory({
                                mode: 'click',
                                blurToSave: true,
                              })}
                              bordered={false}
                              // ref={this.tableRef}
                              ref={this.buttonRef}
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </div>
                </div>
              </Col>
              <Col xl="4">
                <Row>
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4 h5">VM Layout</CardTitle>
                      <p className="card-title-desc">
                        Layout kolom mesin (referensi penempatan SKU)
                      </p>
                      <div className="card bg-primary text-white mb-0">
                        <CardBody>
                          <div className="table-responsive">
                            <Table className="table table-sm mb-0 text-white text-center">
                              <thead>
                                {APPLICATION_ID == 'Redboks' ? (
                                  <tr>
                                    <th colSpan="10">Kubota Top</th>
                                  </tr>
                                ) : (
                                  <tr>
                                    <th colSpan="10">Top</th>
                                  </tr>
                                )}
                              </thead>
                              {APPLICATION_ID == 'Redboks' ? (
                                <tbody>
                                  <tr>
                                    <td>K1</td>
                                    <td>K6</td>
                                    <td>K11</td>
                                    <td>K16</td>
                                  </tr>
                                  <tr>
                                    <td>K2</td>
                                    <td>K7</td>
                                    <td>K12</td>
                                    <td>K17</td>
                                  </tr>
                                  <tr>
                                    <td>K3</td>
                                    <td>K8</td>
                                    <td>K13</td>
                                    <td>K18</td>
                                  </tr>
                                  <tr>
                                    <td>K4</td>
                                    <td>K9</td>
                                    <td>K14</td>
                                    <td>K19</td>
                                  </tr>
                                  <tr>
                                    <td>K5</td>
                                    <td>K10</td>
                                    <td>K15</td>
                                    <td>K20</td>
                                  </tr>
                                </tbody>
                              ) : type == 'kubota' ? (
                                <tbody>
                                  <tr>
                                    <td>K1</td>
                                    <td>K6</td>
                                    <td>K11</td>
                                    <td>K16</td>
                                  </tr>
                                  <tr>
                                    <td>K2</td>
                                    <td>K7</td>
                                    <td>K12</td>
                                    <td>K17</td>
                                  </tr>
                                  <tr>
                                    <td>K3</td>
                                    <td>K8</td>
                                    <td>K13</td>
                                    <td>K18</td>
                                  </tr>
                                  <tr>
                                    <td>K4</td>
                                    <td>K9</td>
                                    <td>K14</td>
                                    <td>K19</td>
                                  </tr>
                                  <tr>
                                    <td>K5</td>
                                    <td>K10</td>
                                    <td>K15</td>
                                    <td>K20</td>
                                  </tr>
                                </tbody>
                              ) : type == 'nagomi' ? (
                                <tbody>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 5 ? null : (
                                            <td key={'A' + i}>{item.key}</td>
                                          )}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 5 ? (
                                            i < 12 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 11 ? (
                                            i < 19 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 18 ? (
                                            i < 26 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 25 ? (
                                            i < 33 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                </tbody>
                              ) : type == 'slant' ? (
                                <tbody>
                                  <tr>
                                    <td>K1</td>
                                    <td>K7</td>
                                    <td>K13</td>
                                    <td>K19</td>
                                    <td>K28</td>
                                  </tr>
                                  <tr>
                                    <td>K2</td>
                                    <td>K8</td>
                                    <td>K14</td>
                                    <td>K20</td>
                                    <td>K29</td>
                                  </tr>
                                  <tr>
                                    <td>K3</td>
                                    <td>K9</td>
                                    <td>K15</td>
                                    <td>K21</td>
                                    <td>K30</td>
                                  </tr>
                                  <tr>
                                    <td>K4</td>
                                    <td>K10</td>
                                    <td>K16</td>
                                    <td>K22</td>
                                    <td>K31</td>
                                  </tr>
                                  <tr>
                                    <td>K5</td>
                                    <td>K11</td>
                                    <td>K17</td>
                                    <td>K23</td>
                                    <td>K32</td>
                                  </tr>
                                  <tr>
                                    <td>K6</td>
                                    <td>K12</td>
                                    <td>K18</td>
                                    <td>K24</td>
                                    <td>K33</td>
                                  </tr>
                                  <tr>
                                    <td>{'⁣'}</td>
                                    <td>{'⁣'}</td>
                                    <td>{'⁣'}</td>
                                    <td>K25</td>
                                    <td>K34</td>
                                  </tr>
                                  <tr>
                                    <td>{'⁣'}</td>
                                    <td>{'⁣'}</td>
                                    <td>{'⁣'}</td>
                                    <td>K26</td>
                                    <td>K35</td>
                                  </tr>
                                  <tr>
                                    <td>{'⁣'}</td>
                                    <td>{'⁣'}</td>
                                    <td>{'⁣'}</td>
                                    <td>K27</td>
                                    <td>K36</td>
                                  </tr>
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 9 ? null : (
                                            <td key={'A' + i}>{item.key}</td>
                                          )}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 9 ? (
                                            i < 20 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 19 ? (
                                            i < 30 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 29 ? (
                                            i < 40 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 39 ? (
                                            i < 50 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 49 ? (
                                            i < 60 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                  <tr>
                                    {dataColumn.map(function (item, i) {
                                      return (
                                        <>
                                          {i > 59 ? (
                                            i < 70 ? (
                                              <td key={'A' + i}>{item.key}</td>
                                            ) : null
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </tr>
                                </tbody>
                              )}
                            </Table>
                          </div>
                        </CardBody>
                      </div>
                    </CardBody>
                  </Card>
                </Row>
                <Row></Row>
                {/*                 
                <div className="d-flex flex-wrap gap-2 float-end mb-4">
                  <Link
                    to="/business-point/list"
                    color="secondary"
                    className="btn btn-secondary"
                    role="button"
                    // onClick={this.handleCancel}
                    disabled={loading}
                  >
                    Cancel
                  </Link>{' '}
                  <Button
                    color="primary"
                    onClick={this.handleSubmit}
                    disabled={loading}
                  >
                    Simpan
                  </Button>
                </div> */}
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
class PriceEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onClik: false,
    };
  }

  static propTypes = {
    value: PropTypes.number,
    onUpdate: PropTypes.func.isRequired,
    onClik: PropTypes.bool,
  };
  static defaultProps = {
    value: 0,
  };
  getValue = (e, values) => {
    const { onUpdate, row, ...rest } = this.props;
    const selection = safeDeepGet(row, 'selection', '');
    const product = safeDeepGet(row, 'product', '');
    const amountHpp = Number(safeDeepGet(values, 'hpp', 0));
    const amountShare = Number(safeDeepGet(values, 'share', 0));
    const price = amountHpp + amountShare;
    const body = {
      selection: selection,
      product: product,
      Hpp: amountHpp,
      Share: amountShare,
      total: price,
    };
    this.props.onPriceChange(body);
    onUpdate(price);
    return price;
  };
  handleOnClick = () => {
    this.setState({ onClik: true });
  };

  render() {
    const { value, onUpdate, modal, row, ...rest } = this.props;
    return [
      <Modal
        key="modal"
        size="lg"
        isOpen={this.state.onClik ? false : modal}
        className={this.props.className}
        // unmountOnClose={this.getValue}
        toggle={() => this.setState({ onClik: true })}
      >
        <ModalHeader toggle={() => this.setState({ onClik: true })} tag="h4">
          {'Set Price'}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={this.getValue}>
            <Row>
              <div className="mb-3">
                <Label className="mt-1" htmlFor="selectAccount">
                  HPP :
                </Label>
                <AvField
                  type="number"
                  id="hpp"
                  name="hpp"
                  value={safeDeepGet(row, 'hpp', 0)}
                  placeholder="Masukan HPP..."
                  className="form-control"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>
            </Row>
            <Row>
              <div className="mb-3">
                <Label className="mt-1" htmlFor="selectAccount">
                  {nameBenefit}
                </Label>
                <AvField
                  type="number"
                  id="share"
                  name="share"
                  value={safeDeepGet(row, 'share', 0)}
                  placeholder={'Masukan ' + nameBenefit + ' ....'}
                  className="form-control"
                  validate={{
                    required: { value: true },
                  }}
                />
              </div>
            </Row>

            <Row>
              <Col>
                <div className="text-end"></div>
              </Col>
              <Col>
                <div className="text-end">
                  {/* <button
                    onClick={this.handleOnClick}
                    className="btn btn-danger"
                  >
                    Cancel
                  </button> */}
                  <button type="submit" className="btn btn-success save-user">
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>,
    ];
  }
}

const mapStateToProps = state => ({
  readPlanogramResult: safeDeepGet(
    state,
    ['businessPoint', 'readPlanogramRetail'],
    {}
  ),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  result: safeDeepGet(state, ['businessPoint', 'updatePlanogramRetail'], {}),
  resultHistory: safeDeepGet(state, ['businessPoint', 'list_history'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  ReadPlanogram: id => dispatch(readPlanogramRetail(id)),
  GetHistory: payload => dispatch(list_history(payload)),
  UpdatePlanogram: payload => dispatch(updatePlanogramRetail(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessPointPlanogram);
